import { z } from "zod";
import {
  quotationSchema as saleOrderZodSchema,
  itemSchema,
} from "../../SaleOrders/types";

export type { default as TCreditNoteBase } from "~/types/serializers/Organizations/CreditNotes/Index";

// TODO: Currently make saleOrder as schema reference. Deprecating invoice
const cnItemSchema = itemSchema
  .omit({
    discountAmount: true, // CN item has no discount
  })
  .merge(
    z.object({
      taxPercentage: z.number().or(z.string()).optional().nullable()
    })
  );

const appliedCreditBaseSchema = z.object({
  id: z.unknown(),
  amount: z.string(),
  appliedAt: z.string(),
  creditNoteId: z.unknown(),
  invoiceId: z.unknown(),
});

export const creditNoteSchema = saleOrderZodSchema
  .omit({
    dueDate: true,
  })
  .merge(
    z.object({
      applicableAmount: z.string(),
      appliedCredits: z
        .array(appliedCreditBaseSchema.nullable())
        .optional()
        .nullable(),
      creditableAmount: z.string(),
      subTotalAmount: z.string(),
      totalTaxAmount: z.string(),
      items: z.array(cnItemSchema.nullable()).optional().nullable(),
    })
);
export type TCreditNote = z.infer<typeof creditNoteSchema>;
export type TProductItem = z.infer<typeof cnItemSchema>;
export type TProductItemRow = {
  rowIndex: number;
  item: TProductItem;
};
