<script setup lang="ts">
import { useForwardProps } from 'radix-vue';

// Component
import EditPage from './Edit.vue';
import { OrganizationsBillsShow } from '~/types/serializers';
import type { TOrderBasedOverwrite } from '~/types/OrderBased';
import { TCurrencyCode, TStatus, TTaxType } from '~/types/Generic';

const props = defineProps<{
  bill: TOrderBasedOverwrite<OrganizationsBillsShow>,
  currency_codes: TCurrencyCode[],
  statuses: TStatus,
  tax_types: TTaxType
}>()

const forwarded = useForwardProps(props)
</script>

<template>
  <EditPage v-bind="forwarded" />
</template>
