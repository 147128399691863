<script setup lang="ts">
import { startCase } from 'lodash';

import { n, t } from '~/entrypoints/i18n';
import { organizationsInvoices } from '~/api';
import { TCustomer, TTaxType } from '~/types/Generic';

defineProps<{
  cnAppliedCredits?: any[];
  cnId?: number;
  contact?: TCustomer;
  currencyCode: string;
  formValues: any;
  summarize: any;
  taxType?: keyof TTaxType;
}>()

const [DefineTaxesTemplate, ReuseTaxesTemplate] = createReusableTemplate()
</script>

<template>
  <DefineTaxesTemplate>
    <template v-for="({ taxName, totalTaxValue }, keyTaxPercentage) in summarize.taxes">
      <div class="flex justify-between gap-1 mb-3">
        <div class="">{{ taxName }} ({{ Number(keyTaxPercentage).toFixed(2) + '%' }})</div>

        <div class="flex justify-between w-1/2">
          <div class="px-3">{{ currencyCode }}</div>
          <div class="text-right">{{ n(totalTaxValue, 'decimal') }}</div>
        </div>
      </div>
    </template>
  </DefineTaxesTemplate>

  <div class="w-full max-w-lg">
    <div class="flex justify-between gap-2 mb-3">
      <div class="py-2">
        {{ t('subtotal') }}
      </div>
      <div class="flex justify-between w-1/3">
        <div class="px-3 py-2">{{ currencyCode }}</div>
        <div class="py-2 text-right">{{ n(summarize.subtotal, 'decimal') }}</div>
      </div>
    </div>

    <!-- additional amount applied -->
    <div class="max-w-xs pl-4 text-sm">
      <ReuseTaxesTemplate v-if="taxType === 'tax_inclusive'" />

      <!-- includes discount -->
      <div class="flex justify-between gap-1 mb-3" v-if="summarize.discountAmount > 0">
        <div class="">{{ startCase(t('includes_discount')) }}</div>

        <div class="flex justify-between w-1/2">
          <div class="px-3">{{ currencyCode }}</div>
          <div class="text-right">{{ n(summarize.discountAmount, 'decimal') }}</div>
        </div>
      </div>
    </div>
    <ReuseTaxesTemplate v-if="taxType === 'tax_exclusive'" />

    <div class="flex justify-between py-3 font-semibold border-t text-md border-slate-400">
      <div>{{ startCase(t('total')) }}</div>
      <div>{{ n(summarize.total, 'decimal') }}</div>
    </div>

    <!-- Credit Note Applications -->
    <div class="border-b-2 border-y border-b-slate-400" v-if="cnAppliedCredits">
      <div class="flex justify-between py-3" v-for="appliedCredit in cnAppliedCredits"
        :key="(appliedCredit.id as number)">
        <div>
          <span class="font-semibold text-slate-700">{{ t('less') }}:</span>
          <Link :href="organizationsInvoices.show.path({ id: appliedCredit.invoiceId })" class="text-blue-600 underline">
          Credit Note ({{ appliedCredit.appliedAt }})</Link>
        </div>
        <div class="flex justify-between w-1/3">
          <span class="px-3">{{ currencyCode }}</span>
          <span>{{ n(parseFloat(appliedCredit.amount), 'decimal') }}</span>
        </div>
      </div>
    </div>

    <!-- Credit Available -->
    <div
      class="flex items-center justify-between py-3 text-lg font-semibold border-b border-slate-400 border-b-slate-400"
      v-if="cnAppliedCredits">
      <AllocateCredit :contact="contact" :creditNoteId="cnId" />
      <span>{{ n(summarize.total - cnAppliedCredits.reduce((total, appliedCredit) => total + parseFloat(appliedCredit.amount), 0), 'decimal') }}</span>
    </div>
  </div>
</template>
