import { Link } from "@inertiajs/vue3";
import type { ColumnDef } from "@tanstack/vue-table";
import { organizationsInvoices } from "~/api";
import { Input } from "~/Components/ui/input";
import { n, t } from "~/entrypoints/i18n";
import { OrganizationsInvoicesIndex } from "~/types/serializers";

export const columnDef: ColumnDef<OrganizationsInvoicesIndex>[] = [
  {
    accessorKey: "invoice",
    header: ({ column }) => t(column.id),
    cell: ({ row }) => {
      return h(
        Link,
        {
          href: organizationsInvoices.show.path({ id: row.original.id }),
          class: "font-medium",
        },
        () => [row.original.number]
      );
    },
  },
  {
    accessorKey: "issue_date",
    header: ({ column }) => t(column.id),
    cell: ({ row }) => {
      return row.original.issueDate;
    },
  },
  {
    accessorKey: "total",
    header: ({ column }) => t(column.id),
    cell: ({ row }) => {
      return h("span", n(parseFloat(row.original.amount), "decimal"));
    },
  },
  {
    accessorKey: "balance",
    header: ({ column }) => t(column.id),
    cell: ({ row }) => {
      return h("span", n(parseFloat(row.original.balanceAmount), 'decimal'));
    },
  },
  {
    accessorKey: "apply_amount",
    meta: {
      attributes: {
        class: "w-48",
      },
    },
    header: () => {
      return h("div", { class: "flex space-x-2 justify-end" }, t('amount_to_credit'));
    },
    cell: (params: any) => {
      const binder = {
        modelValue: params.modelValue,
        onUpdate: params["onUpdate:modelValue"],
      };
      return h(Input, {
        ...binder,
        class: "text-right",
        placeholder: '0.00',
      });
    },
  },
];

// Invoice	Description	Date	Total	Balance	Apply Amount
