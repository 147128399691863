<script setup lang="ts">
import {
  FlexRender,
  getCoreRowModel,
  useVueTable,
} from '@tanstack/vue-table'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '~/Components/ui/table'

import { valueUpdater } from '@/lib/utils'

import { organizationsInvoices } from '~/api';
import { columnDef } from './columns';
import { ApiOptions, TCustomer } from '~/types/Generic';
import { OrganizationsInvoicesIndex } from '~/types/serializers';
import { extend } from 'lodash';

// interface DataTableProps<T> {
//   columns: ColumnDef<T, any>[]
//   data: T[]
// }
const props = defineProps<{
  contact?: TCustomer,
  invoiceId?: number,
  modelValue?: number
}>()

const emits = defineEmits<{
  // (e: 'update:modelValue', payload: string | number): void
  "update:modelValue": [payload: string | number]
}>()

const componentField = {
  'onUpdate:modelValue': (value: number | string) => {
    console.log('value', value)
    const val = typeof value === 'string' ? parseFloat(value) : value
    emits('update:modelValue', val)
  },
}

const outstandingInvoices = ref<OrganizationsInvoicesIndex[]>([])
const moduleApi = useModuleApiStore()
const apiRoute = moduleApi.initCollectionFor('organizationsInvoices', organizationsInvoices)

const rowSelection = ref({})
const table = useVueTable({
  get data() { return outstandingInvoices.value },
  get columns() { return columnDef },
  state: {
    get rowSelection() { return rowSelection.value },
  },
  enableRowSelection: true,
  onRowSelectionChange: updaterOrValue => valueUpdater(updaterOrValue, rowSelection),
  getCoreRowModel: getCoreRowModel()
})

/**
 * Load earlier list of Tax and Account
 */
watch(() => props.contact, () => {
  loadOutstandingInvoices()
}, {
  immediate: true
})

async function loadOutstandingInvoices() {
  if (!props.contact) return

  const options: ApiOptions = {
    params: {
      query: {
        query: props.contact.name,
        status: 'approved'
      }
    },
    accept: 'json'
  }
  const response = await apiRoute.fetchIndex(options)
  outstandingInvoices.value = response.props.invoices
}
</script>

<template>
  <div class="space-y-4">
    <div class="border rounded-md">
      <Table>
        <TableHeader class="[&_tr_th]:bg-primary-foreground">
          <TableRow v-for=" headerGroup in table.getHeaderGroups()" :key="headerGroup.id">
            <TableHead v-for="header in headerGroup.headers" :key="header.id"
              v-bind="header.column.columnDef.meta?.attributes">
              <FlexRender v-if="!header.isPlaceholder" :render="header.column.columnDef.header"
                :props="header.getContext()" />
            </TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          <template v-if="table.getRowModel().rows?.length">
            <TableRow v-for="row in table.getRowModel().rows" :key="row.id"
              :data-state="row.getIsSelected() && 'selected'">
              <TableCell v-for="cell in row.getVisibleCells()" :key="cell.id">
                <FlexRender :render="cell.column.columnDef.cell" :props="extend({}, cell.getContext(), componentField)" />
              </TableCell>
            </TableRow>
          </template>

          <TableRow v-else>
            <TableCell :colspan="columnDef.length" class="h-24 text-center">
              {{ $t('table.no_result') }}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>

    <div class="flex justify-end" v-if="false">
      <div class="grid w-2/5 grid-cols-3 !text-[color:hsl(var(--foreground))]">
        <div class="col-span-2 py-2">Outstanding Credit Balance</div><span class="py-2 text-right">5124</span>
        <div class="col-span-2 py-2 border-b border-slate-600">Total Amount to Credit</div><span
          class="py-2 text-right border-b border-slate-600">0.00</span>
        <div class="col-span-2 py-2 font-semibold border-b-4 border-double border-b-slate-700">Remaining Credit</div>
        <span class="py-2 font-semibold text-right border-b-4 border-double border-b-slate-700">0.00</span>
      </div>
    </div>
  </div>
</template>
