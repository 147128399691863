<script setup lang="ts">
import { assignIn, isNil, map, startCase } from 'lodash';
import { computed, reactive } from 'vue';
import { configure, FieldArray, useForm } from 'vee-validate';
import { Link } from '@inertiajs/vue3';
import { organizationsBills } from '~/api';
import { OrganizationsBillsShow } from '~/types/serializers';
import { removeBlankItem } from '~/lib/utils';
import { t } from '~/entrypoints/i18n';
import { toTypedSchema } from '@vee-validate/zod';
import { useInvoiceFormData } from '~/stores/invoiceFormData';
import { useModuleApiStore } from '~/stores/moduleApi';
import { watch } from 'vue';

// Shadcn UI
import { Card, CardContent, CardHeader, CardTitle } from '~/Components/ui/card';
import { FormControl, FormField, FormItem, FormLabel } from '~/Components/ui/form';
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '~/Components/ui/select';
import { TCurrencyCode, TStatus, TTaxType } from '~/types/Generic';
import { useErrorHandler } from '~/composables/useErrorHandler';
import Textarea from '~/Components/ui/textarea/Textarea.vue';
import Button from '~/Components/ui/button/Button.vue';
import Input from '~/Components/ui/input/Input.vue';

// Custom
import AddressFields from '~/Components/v8/AddressFields.vue';
import CustomersCombobox from '~/Components/v8/CustomersCombobox.vue';
import ComboBox from '~/Components/v8/ComboBox.vue';
import DatePicker from '~/Components/v8/DatePicker.vue';
import PageHeading from '~/Components/v8/PageHeading.vue';
import SingleWrap from '~/Components/Wrapper/SingleWrap.vue';

// Component
import { billSchema, TBill, TProductItem } from './types';
import ProductItems from './components/ProductItems/ProductItems.vue';
import { calculateResults } from '~/lib/calculations';
import FinalCalculation from '~/Components/v8/FinalCalculation.vue';

configure({
  validateOnBlur: false,
  validateOnChange: false,
  validateOnInput: false,
  validateOnModelUpdate: false
})

// configure API use in this component.
const API = organizationsBills

const props = defineProps<{
  bill: OrganizationsBillsShow,
  currency_codes: TCurrencyCode[],
  statuses: TStatus,
  tax_types: TTaxType
}>()

const isEditMode = computed(() => !isNil(props.bill?.id))
const moduleApi = useModuleApiStore()
moduleApi.setCreate(API.create)
const storeFormInvoice = useInvoiceFormData()
const taxes = computed(() => storeFormInvoice.taxForItem)

const summarize = reactive({
  total: 0,
  subtotal: 10,
  discount: 1,
  taxes: {}
})

const initialValues = isEditMode.value ? props.bill : assignIn({}, props.bill, { items: [] })

const form = useForm({
  validationSchema: toTypedSchema(billSchema),
  initialValues,
  // remove items. we start by nothing to avoid trigger validation
})

useErrorHandler().generic(form.errors)

watch([() => form.values.items, () => form.values.taxType], ([items, taxType], [oldItems, oldTaxType]) => {
  const results = calculateResults({
    summarize,
    items,
    taxes,
    taxType,
  })

  // reset items.tax a CHANGE/SWITCH happens of to taxType == no_tax
  // otherwise, skip reset.
  if (taxType === 'no_tax' && oldTaxType !== 'no_tax') {
    form.values.items?.forEach((item) => {
      console.log('reset item', item)
      resetTax(item)
    })
  }

  Object.assign(summarize, results)
}, {
  deep: true
})

function resetTax(item: TProductItem) {
  if (!item) return

  item.taxId = null
  item.taxPercentage = null
}

const onSubmit = form.handleSubmit((values: TBill) => {
  values.items = removeBlankItem(values.items) as TProductItem[]
  if (isEditMode.value) {
    moduleApi.gotoUpdate(values, values.id, API.update)
  } else {
    moduleApi.gotoCreate({
      bill: {
        ...values
      }
    })
  }
})

const comboBoxItems = map(props.currency_codes, (currency) => {
  return {
    value: currency.currency_code,
    label: `${currency.flag} ${currency.currency_code} ${currency.name}`
  }
})

</script>

<template>
  <form @submit="onSubmit" autocomplete="off">
    <!-- https://gist.github.com/niksumeiko/360164708c3b326bd1c8 -->
    <input autocomplete="false" name="hidden" type="text" style="display:none;">

    <div class="justify-between flex-1 h-full p-8 space-y-8 md:flex">
      <PageHeading :pageId="isEditMode ? 'edit_bill' : 'add_bill'" />
      <div class="flex gap-4">

        <Link :href="API.index.path()" as="button" replace>
          <Button type="button" variant="outline">{{ t('cancel') }}</Button>
        </Link>
        <Button type="submit" variant="outline" v-if="isEditMode">{{ startCase(t('update_bill')) }}</Button>
        <Button type="submit" v-else>{{ t('save') }}</Button>
      </div>
    </div>

    <SingleWrap>
      <Card>
        <CardHeader>
          <CardTitle>{{ t('bill_details') }}</CardTitle>
        </CardHeader>
        <CardContent class="mb-8 space-y-5">
          <div class="grid gap-4 grid-col-1 lg:grid-cols-5 ">
            <FormField v-slot="{ componentField }" name="contactId">
              <FormItem>
                <FormLabel>{{ t('contact') }} *</FormLabel>
                <FormControl>
                  <CustomersCombobox v-bind="componentField" :contact="isEditMode ? bill.contact : undefined" />
                </FormControl>
              </FormItem>
            </FormField>

            <FormField v-slot="{ componentField }" name="reference">
              <FormItem>
                <FormLabel>{{ t('reference') }}</FormLabel>
                <FormControl>
                  <Input type="text" placeholder="" v-bind="componentField" />
                </FormControl>
              </FormItem>
            </FormField>

            <div class="grid grid-cols-2 col-start-4 row-span-2 gap-4">
              <FormField v-slot="{ componentField }" name="number">
                <FormItem class="flex-auto col-span-2">
                  <FormLabel>{{ t('bill_no') }}</FormLabel>
                  <FormControl>
                    <Input type="text" placeholder="INV-9874" v-bind="componentField" />
                  </FormControl>
                </FormItem>
              </FormField>

              <FormField v-slot="{ componentField }" name="issueDate">
                <FormItem class="flex-auto">
                  <FormLabel>{{ t('issue_date') }} *</FormLabel>
                  <FormControl>
                    <DatePicker v-bind="componentField" />
                  </FormControl>
                </FormItem>
              </FormField>

              <FormField v-slot="{ componentField }" name="dueDate">
                <FormItem>
                  <FormLabel>{{ t('due_date') }} *</FormLabel>
                  <FormControl>
                    <DatePicker v-bind="componentField" />
                  </FormControl>
                </FormItem>
              </FormField>
            </div>

            <FormField v-slot="{ componentField }" name="billingAddress">
              <FormItem class="col-start-5 row-span-2">
                <FormLabel>{{ startCase(t('billing_address')) }}</FormLabel>
                <FormControl>
                  <AddressFields v-bind="componentField" />
                </FormControl>
              </FormItem>
            </FormField>

            <div class="flex gap-4">
              <FormField v-slot="{ componentField }" name="taxType">
                <FormItem class="flex-auto">
                  <FormLabel>{{ t('tax_type') }} *</FormLabel>
                  <Select v-bind="componentField">
                    <SelectTrigger class="w-full">
                      <SelectValue placeholder="Select a type" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectGroup>
                        <SelectItem v-for="(type, key) in tax_types" :value="key" :key="type">
                          {{ t(type) }}
                        </SelectItem>
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                </FormItem>
              </FormField>
              <FormField v-slot="{ componentField }" name="status">
                <FormItem class="flex-auto">
                  <FormLabel>{{ t('status') }} *</FormLabel>
                  <Select v-bind="componentField">
                    <SelectTrigger class="w-full">
                      <SelectValue placeholder="Select a status" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectGroup>
                        <SelectItem v-for="(status, key) in statuses" :value="key" :key="key">
                          {{ t(status) }}
                        </SelectItem>
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                </FormItem>
              </FormField>
            </div>

            <FormField v-slot="{ componentField }" name="currencyCode">
              <FormItem>
                <FormLabel>{{ t('currency_code') }} *</FormLabel>
                <FormControl>
                  <ComboBox v-bind="componentField" :listItems="comboBoxItems" />
                </FormControl>
              </FormItem>
            </FormField>
          </div>

          <FieldArray v-slot="{ fields }" name="items">
            <ProductItems :fields="fields" />
          </FieldArray>

          <div class="grid justify-end grid-cols-4 pr-2">
            <div class="col-span-1 col">
              <FormField v-slot="{ componentField }" name="notes">
                <FormItem>
                  <FormLabel>{{ t('notes') }}</FormLabel>
                  <FormControl>
                    <Textarea type="text" placeholder="Notes" v-bind="componentField" />
                  </FormControl>
                </FormItem>
              </FormField>
            </div>
            <div class="flex justify-end col-span-2 col-start-3">
              <FinalCalculation
                :formValues="form.values"
                :summarize="summarize"
                :currencyCode="bill.currencyCode"
                :taxType="(form.values.taxType as keyof TTaxType)" />
            </div>
          </div>
        </CardContent>
      </Card>
    </SingleWrap>
  </form>
</template>
