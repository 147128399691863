import { get as _get, set as _set, forEach } from "lodash";

type Summarize = {
  subtotal: number;
  discountAmount?: number;
  total: number;
  totalDiscountAmount?: number;
  taxes: any;
}

type CalculateParams = {
  summarize: Summarize;
  items: any;
  taxes: any;
  taxType?: string;
}

export function calculateResults({ summarize, items, taxes, taxType }: CalculateParams) : Summarize {
  let subTotal = 0;
  let total = 0;
  let totalDiscountAmount = 0;

  // reset before calculation
  summarize.taxes = {};

  forEach(items, function (item, index) {
    if (!item) return;

    let amount = 0,
      taxedValue = 0;

    const price = Number(item.price ?? 0);
    const quantity = Number(item.quantity ?? 0);
    const discountAmount = 0; // credit note don't have discount
    const theTax = _get(toRaw(taxes.value), index);
    const taxPercentage = Number(theTax?.totalPercentage ?? 0);

    if (!Number.isNaN(price) && !Number.isNaN(quantity)) {
      amount = price * quantity;

      if (!Number.isNaN(taxPercentage)) {
        // exclusive tax
        if (taxType === "tax_exclusive") {
          taxedValue = (amount * taxPercentage) / 100;
          // inclusive tax
        } else if (taxType === "tax_inclusive") {
          taxedValue =
            (taxPercentage / 100) * ((amount * 100) / (taxPercentage + 100));
        }

        _set(summarize.taxes, taxPercentage, {
          taxName: theTax?.name,
          totalTaxValue: taxedValue,
        });
      }
    }

    subTotal += amount;
    total += amount - discountAmount + taxedValue;
    totalDiscountAmount += discountAmount;
  });
  summarize.discountAmount = totalDiscountAmount;
  summarize.subtotal = subTotal;
  summarize.total = total;

  return summarize
}
