<script setup lang="ts">
import { assignIn, forEach, get, isNil, map, set as _set, startCase } from 'lodash';
import { removeBlankItem } from '~/lib/utils';
import { computed, reactive } from 'vue';
import { configure, FieldArray, useForm } from 'vee-validate';
import { createReusableTemplate } from '@vueuse/core';
import { organizationsCreditNotes, organizationsInvoices } from '~/api';
import { OrganizationsCreditNotesShow, OrganizationsTaxesIndex } from '~/types/serializers';
import { n, t } from '~/entrypoints/i18n';
import { toTypedSchema } from '@vee-validate/zod';
import { useInvoiceFormData } from '~/stores/invoiceFormData';
import { useModuleApiStore } from '~/stores/moduleApi';
import { watch } from 'vue';

// Shadcn UI
import { Card, CardContent, CardHeader, CardTitle } from '~/Components/ui/card';
import { FormControl, FormField, FormItem, FormLabel } from '~/Components/ui/form';
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '~/Components/ui/select';
import { TCurrencyCode, TCustomer, TStatus, TTaxType } from '~/types/Generic';
import { useErrorHandler } from '~/composables/useErrorHandler';
import Button from '~/Components/ui/button/Button.vue';
import Input from '~/Components/ui/input/Input.vue';
import Textarea from '~/Components/ui/textarea/Textarea.vue';

// Custom
import AddressFields from '~/Components/v8/AddressFields.vue';
import CustomersCombobox from '~/Components/v8/CustomersCombobox.vue';
import ComboBox from '~/Components/v8/ComboBox.vue';
import DatePicker from '~/Components/v8/DatePicker.vue';
import PageHeading from '~/Components/v8/PageHeading.vue';
import SingleWrap from '~/Components/Wrapper/SingleWrap.vue';

// Component
import { creditNoteSchema, TCreditNote, TProductItem } from './types';
import ProductItems from './components/ProductItems/ProductItems.vue';
import { Link } from '@inertiajs/vue3';
import AllocateCredit from '~/pages/Organizations/CreditNotes/components/AllocateCredit.vue';

configure({
  validateOnBlur: false,
  validateOnChange: false,
  validateOnInput: false,
  validateOnModelUpdate: false
})

// configure API use in this component.
const API = organizationsCreditNotes

const props = defineProps<{
  credit_note: OrganizationsCreditNotesShow,
  currency_codes: TCurrencyCode[],
  statuses: TStatus,
  tax_types: TTaxType
}>()

const [DefineTaxesTemplate, ReuseTaxesTemplate] = createReusableTemplate()
const isEditMode = computed(() => !isNil(props.credit_note?.id))
const moduleApi = useModuleApiStore()
moduleApi.setCreate(API.create)
const storeFormInvoice = useInvoiceFormData()
const taxes = computed(() => storeFormInvoice.taxForItem)
const contact = ref<TCustomer>()

const summarize = reactive({
  subtotal: 10,
  total: 0,
  discountAmount: 0,
  taxes: {}
})

const initialValues = isEditMode.value ? props.credit_note : assignIn({}, props.credit_note, { items: [] })

const form = useForm({
  validationSchema: toTypedSchema(creditNoteSchema),
  initialValues,
  // remove items. we start by nothing to avoid trigger validation
})

useErrorHandler().generic(form.errors)

watch([() => form.values.items, () => form.values.taxType, () => taxes.value], ([items, taxType]) => {
  let subTotal = 0
  let total = 0
  let totalDiscountAmount = 0

  // reset before calculation
  summarize.taxes = {}

  forEach(items, function (item, index) {
    if (!item) return

    let amount = 0,
      taxedValue = 0

    const price = Number(item.price ?? 0);
    const quantity = Number(item.quantity ?? 0);
    const discountAmount = 0; // credit not don't have discount
    const theTax = get(toRaw(taxes.value), index) as OrganizationsTaxesIndex
    const taxPercentage = Number(theTax?.totalPercentage ?? 0)

    if (!Number.isNaN(price) && !Number.isNaN(quantity)) {
      amount = price * quantity

      if (!Number.isNaN(taxPercentage)) {
        // exclusive tax
        if (taxType === 'tax_exclusive') {
          taxedValue = amount * taxPercentage / 100
          // inclusive tax
        } else if (taxType === 'tax_inclusive') {
          taxedValue = taxPercentage / 100 * (amount * 100 / (taxPercentage + 100))
        }

        _set(summarize.taxes, taxPercentage, {
          taxName: theTax?.name,
          totalTaxValue: taxedValue
        })
      }
    }

    subTotal += amount
    total += amount - discountAmount + taxedValue
    totalDiscountAmount += discountAmount
  })
  summarize.discountAmount = totalDiscountAmount
  summarize.subtotal = subTotal
  summarize.total = total
}, {
  deep: true
})

const onSubmit = form.handleSubmit((values: TCreditNote) => {
  console.log('beforeSubmit', values.items)
  values.items = removeBlankItem<TProductItem>(values.items)
  if (isEditMode.value) {
    moduleApi.gotoUpdate(values, values.id, API.update)
  } else {
    moduleApi.gotoCreate({
      credit_note: {
        ...values
      }
    })
  }
})

const comboBoxItems = map(props.currency_codes, (currency) => {
  return {
    value: currency.currency_code,
    label: `${currency.flag} ${currency.currency_code} ${currency.name}`
  }
})

</script>

<template>
  <form @submit="onSubmit" autocomplete="off">
    <!-- https://gist.github.com/niksumeiko/360164708c3b326bd1c8 -->
    <input autocomplete="false" name="hidden" type="text" style="display:none;">

    <div class="justify-between flex-1 h-full p-8 space-y-8 md:flex">
      <PageHeading :pageId="isEditMode ? 'edit_credit_note' : 'add_credit_note'" />
      <div class="flex gap-4">

        <Link :href="API.index.path()" as="button" replace>
        <Button type="button" variant="outline">{{ t('cancel') }}</Button>
        </Link>
        <Button type="submit" variant="outline" v-if="isEditMode">{{ startCase(t('update_credit_note')) }}</Button>
        <Button type="submit" v-else>{{ t('save') }}</Button>
      </div>
    </div>

    <SingleWrap>
      <Card>
        <CardHeader>
          <CardTitle>{{ t('credit_note_details', [credit_note.number]) }}</CardTitle>
        </CardHeader>
        <CardContent class="mb-8 space-y-5">
          <div class="grid gap-4 grid-col-1 lg:grid-cols-5 ">
            <FormField v-slot="{ componentField }" name="customerId">
              <FormItem>
                <FormLabel>{{ t('customer') }} *</FormLabel>
                <FormControl>
                  <CustomersCombobox v-bind="componentField" :contact="isEditMode ? credit_note.customer : undefined"
                    @update:contact="contact = $event" />
                </FormControl>
              </FormItem>
            </FormField>

            <FormField v-slot="{ componentField }" name="reference">
              <FormItem>
                <FormLabel>{{ t('reference') }}</FormLabel>
                <FormControl>
                  <Input type="text" placeholder="" v-bind="componentField" />
                </FormControl>
              </FormItem>
            </FormField>

            <div class="grid grid-cols-2 col-start-4 row-span-2 gap-4">
              <FormField v-slot="{ componentField }" name="number">
                <FormItem class="flex-auto col-span-2">
                  <FormLabel>{{ t('credit_note_no') }}</FormLabel>
                  <FormControl>
                    <Input type="text" placeholder="INV-9874" v-bind="componentField" />
                  </FormControl>
                </FormItem>
              </FormField>

              <FormField v-slot="{ componentField }" name="issueDate">
                <FormItem class="flex-auto">
                  <FormLabel>{{ t('issue_date') }} *</FormLabel>
                  <FormControl>
                    <DatePicker v-bind="componentField" />
                  </FormControl>
                </FormItem>
              </FormField>
            </div>

            <FormField v-slot="{ componentField }" name="billingAddress">
              <FormItem class="col-start-5 row-span-2">
                <FormLabel>{{ startCase(t('billing_address')) }}</FormLabel>
                <FormControl>
                  <AddressFields v-bind="componentField" />
                </FormControl>
              </FormItem>
            </FormField>

            <div class="flex gap-4">
              <FormField v-slot="{ componentField }" name="taxType">
                <FormItem class="flex-auto">
                  <FormLabel>{{ t('tax_type') }} *</FormLabel>
                  <Select v-bind="componentField">
                    <SelectTrigger class="w-full">
                      <SelectValue placeholder="Select a type" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectGroup>
                        <SelectItem v-for="(type, key) in tax_types" :value="key" :key="key">
                          {{ t(key) }}
                        </SelectItem>
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                </FormItem>
              </FormField>
              <FormField v-slot="{ componentField }" name="status">
                <FormItem class="flex-auto">
                  <FormLabel>{{ t('status') }} *</FormLabel>
                  <Select v-bind="componentField">
                    <SelectTrigger class="w-full">
                      <SelectValue placeholder="Select a status" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectGroup>
                        <SelectItem v-for="(status, key) in statuses" :value="key" :key="key">
                          {{ t(key) }}
                        </SelectItem>
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                </FormItem>
              </FormField>
            </div>

            <FormField v-slot="{ componentField }" name="currencyCode">
              <FormItem>
                <FormLabel>{{ t('currency_code') }} *</FormLabel>
                <FormControl>
                  <ComboBox v-bind="componentField" :listItems="comboBoxItems" />
                </FormControl>
              </FormItem>
            </FormField>
          </div>

          <FieldArray v-slot="{ fields }" name="items">
            <ProductItems :fields="fields" />
          </FieldArray>

          <DefineTaxesTemplate>
            <template v-for="({ taxName, totalTaxValue }, keyTaxPercentage) in summarize.taxes">
              <div class="flex justify-between gap-1 mb-3">
                <div class="">{{ taxName }} ({{ Number(keyTaxPercentage).toFixed(2) + '%' }})</div>

                <div class="flex justify-between w-1/2">
                  <div class="px-3">{{ credit_note.currencyCode }}</div>
                  <div class="text-right">{{ n(totalTaxValue, 'decimal') }}</div>
                </div>
              </div>
            </template>
          </DefineTaxesTemplate>

          <div class="grid justify-end grid-cols-4 pr-2">
            <div class="col-span-1 col">
              <FormField v-slot="{ componentField }" name="notes">
                <FormItem>
                  <FormLabel>{{ t('notes') }}</FormLabel>
                  <FormControl>
                    <Textarea type="text" placeholder="Notes" v-bind="componentField" />
                  </FormControl>
                </FormItem>
              </FormField>
            </div>
            <div class="flex justify-end col-span-2 col-start-3">
              <div class="w-full max-w-lg">
                <div class="flex justify-between gap-2 mb-3">
                  <div class="py-2">
                    {{ t('subtotal') }}
                  </div>
                  <div class="flex justify-between w-1/3">
                    <div class="px-3 py-2">{{ credit_note.currencyCode }}</div>
                    <div class="py-2 text-right">{{ n(summarize.subtotal, 'decimal') }}</div>
                  </div>
                </div>
                <!-- additional amount applied -->
                <div class="max-w-xs pl-4 text-sm">
                  <ReuseTaxesTemplate v-if="form.values?.taxType === 'tax_inclusive'" />

                  <!-- includes discount -->
                  <div class="flex justify-between gap-1 mb-3" v-if="summarize.discountAmount > 0">
                    <div class="">{{ startCase(t('includes_discount')) }}</div>

                    <div class="flex justify-between w-1/2">
                      <div class="px-3">{{ credit_note.currencyCode }}</div>
                      <div class="text-right">{{ n(summarize.discountAmount, 'decimal') }}</div>
                    </div>
                  </div>
                </div>
                <ReuseTaxesTemplate v-if="form.values?.taxType === 'tax_exclusive'" />

                <div class="flex justify-between py-3 font-semibold border-t text-md border-slate-400">
                  <div>{{ startCase(t('total_credit')) }}</div>
                  <div>{{ n(summarize.total, 'decimal') }}</div>
                </div>

                <!-- Credit Note Applications -->
                <div class="border-b-2 border-y border-b-slate-400">
                  <div class="flex justify-between py-3" v-for="appliedCredit in credit_note.appliedCredits" :key="(appliedCredit.id as number)">
                    <div>
                      <span class="font-semibold text-slate-700">{{ t('less') }}:</span> <Link :href="organizationsInvoices.show.path({ id: appliedCredit.invoiceId })"
                        class="text-blue-600 underline">Credit Note ({{ appliedCredit.appliedAt }})</Link>
                    </div>
                    <div class="flex justify-between w-1/3">
                      <span class="px-3">{{ credit_note.currencyCode }}</span>
                      <span>{{ n(parseFloat(appliedCredit.amount), 'decimal') }}</span>
                    </div>
                  </div>
                </div>
                <!-- Credit Available -->
                <div class="flex items-center justify-between py-3 text-lg font-semibold border-b border-slate-400 border-b-slate-400">
                  <AllocateCredit :contact="contact" :creditNoteId="credit_note.id" />
                  <span>{{ n(summarize.total - credit_note.appliedCredits.reduce((total, appliedCredit) => total + parseFloat(appliedCredit.amount), 0) , 'decimal') }}</span>
                </div>

              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    </SingleWrap>
  </form>
</template>
