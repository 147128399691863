<script setup lang="ts">
import PageHeading from '~/Components/v8/PageHeading.vue';
import PagyMetadata from '~/types/Metadata';
import { usePage } from '@inertiajs/vue3';
import { organizationsBills } from '~/api';

// components references
import { columns } from './components/columns';
import { TOrderBasedOverwrite } from '~/types/OrderBased';
import type { OrganizationsBillsIndex } from '~/types/serializers';
import DataTable from './components/DataTable.vue';

defineProps<{ bills: TOrderBasedOverwrite<OrganizationsBillsIndex>[], metadata: PagyMetadata }>()
const moduleApi = useModuleApiStore()
moduleApi.setVersion(usePage().version)
moduleApi.setNew(organizationsBills.new)
moduleApi.setShow(organizationsBills.show)
moduleApi.setEdit(organizationsBills.edit)
moduleApi.setDestroy(organizationsBills.destroy)

provide('action-show', true)
</script>

<template>
  <div class="flex-col flex-1 h-full p-8 space-y-8 md:flex">
    <PageHeading pageId="bills" />
    <DataTable :data="bills" :columns="columns" />
  </div>
</template>
