<script setup lang="ts">
import { assignIn, forEach, get, isNil, map, set, startCase, upperCase } from 'lodash';
import { back, removeBlankItem } from '~/lib/utils';
import { computed, reactive } from 'vue';
import { configure, FieldArray, useForm } from 'vee-validate';
import { createReusableTemplate } from '@vueuse/core';
import { OrganizationsInvoicesShow } from '~/types/serializers';
import { organizationsInvoices } from '~/api';
import { toTypedSchema } from '@vee-validate/zod';
import { useInvoiceFormData } from '~/stores/invoiceFormData';
import { useModuleApiStore } from '~/stores/moduleApi';
import { watch } from 'vue';

// Shadcn UI
import { Card, CardContent, CardHeader, CardTitle } from '~/Components/ui/card';
import { FormControl, FormField, FormItem, FormLabel } from '~/Components/ui/form';
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '~/Components/ui/select';
import { TCurrencyCode, TStatus, TTaxType } from '~/types/Generic';
import { useErrorHandler } from '~/composables/useErrorHandler';
import Textarea from '~/Components/ui/textarea/Textarea.vue';
import Button from '~/Components/ui/button/Button.vue';
import Input from '~/Components/ui/input/Input.vue';

// Custom
import AddressFields from '~/Components/v8/AddressFields.vue';
import CustomersCombobox from '~/Components/v8/CustomersCombobox.vue';
import ComboBox from '~/Components/v8/ComboBox.vue';
import DatePicker from '~/Components/v8/DatePicker.vue';
import PageHeading from '~/Components/v8/PageHeading.vue';
import SingleWrap from '~/Components/Wrapper/SingleWrap.vue';

// Component
import { invoiceSchema, TInvoice } from './types';
import ProductItems from './components/ProductItems/ProductItems.vue';
import InvoiceNextAction from './components/InvoiceNextAction.vue';

configure({
  validateOnBlur: false,
  validateOnChange: false,
  validateOnInput: false,
  validateOnModelUpdate: false
})

// configure API use in this component.
const API = organizationsInvoices

const props = defineProps<{
  invoice: OrganizationsInvoicesShow,
  currency_codes: TCurrencyCode[],
  statuses: TStatus,
  tax_types: TTaxType
}>()

const [DefineTaxesTemplate, ReuseTaxesTemplate] = createReusableTemplate()
const isEditMode = computed(() => !isNil(props.invoice.id))
const moduleApi = useModuleApiStore()
moduleApi.setCreate(API.create)
const storeFormInvoice = useInvoiceFormData()
const taxes = computed(() => storeFormInvoice.taxForItem)

const summarize = reactive({
  subtotal: 10,
  discount: 1,
  taxes: {}
})

const total = computed(() => summarize.subtotal)

const initialValues = isEditMode.value ? props.invoice : assignIn({}, props.invoice, { items: [] })

const form = useForm({
  validationSchema: toTypedSchema(invoiceSchema),
  initialValues,
  // remove items. we start by nothing to avoid trigger validation
})

useErrorHandler().generic(form.errors)

watch([() => form.values.items, () => form.values.taxType, () => taxes.value], ([items, taxType, watchedTaxes]) => {
  let subTotal = 0

  // reset before calculation
  summarize.taxes = {}

  forEach(items, function (item, index) {
    if (!item) return

    let amount = 0,
      taxedValue = 0

    const price = Number(item.price);
    const quantity = Number(item.quantity);
    const discountAmount = Number(item.discountAmount);
    const theTax = get(toRaw(taxes.value), index)
    const taxPercentage = Number(theTax?.totalPercentage)

    if (!Number.isNaN(price) && !Number.isNaN(quantity)) {
      amount = price * quantity

      if (!Number.isNaN(taxPercentage)) {
        // exclusive tax
        if (taxType === 'tax_exclusive') {
          taxedValue = amount * taxPercentage / 100
          // inclusive tax
        } else if (taxType === 'tax_inclusive') {
          taxedValue = taxPercentage / 100 * (amount * 100 / (taxPercentage + 100))
        }

        set(summarize.taxes, taxPercentage, {
          taxName: theTax?.name,
          totalTaxValue: Number(taxedValue).toFixed(2)
        })
      }
    }

    subTotal += amount - discountAmount + taxedValue
  })
  summarize.subtotal = subTotal
}, {
  deep: true
})

const onSubmit = form.handleSubmit((values: TInvoice) => {
  console.log('beforeSubmit', values.items)
  values.items = removeBlankItem(values.items)
  if (isEditMode.value) {
    moduleApi.gotoUpdate(values, values.id, API.update)
  } else {
    moduleApi.gotoCreate({
      invoice: {
        ...values
      }
    })
  }
})

const comboBoxItems = map(props.currency_codes, (currency) => {
  return {
    value: currency.currency_code,
    label: `${currency.flag} ${currency.currency_code} ${currency.name}`
  }
})

</script>

<template>
  <form @submit="onSubmit" autocomplete="off">
    <!-- https://gist.github.com/niksumeiko/360164708c3b326bd1c8 -->
    <input autocomplete="false" name="hidden" type="text" style="display:none;">
    <div class="justify-between flex-1 h-full p-8 space-y-8 md:flex">
      <PageHeading :pageId="isEditMode ? 'edit_invoice' : 'add_invoice'" />
      <div class="flex gap-4">
        <Button type="button" @click="back" variant="outline">{{ $t('cancel') }}</Button>
        <Button type="submit" variant="outline" v-if="isEditMode">{{ $t('update_invoice') }}</Button>
        <InvoiceNextAction :status="invoice.status" :id="invoice?.id" v-if="isEditMode" />
        <Button type="submit" v-else>{{ $t('save') }}</Button>
      </div>
    </div>

    <SingleWrap>
      <Card>
        <CardHeader>
          <CardTitle>{{ $t('invoice_details') }}</CardTitle>
        </CardHeader>
        <CardContent class="mb-8 space-y-5">
          <div class="grid gap-4 grid-col-1 lg:grid-cols-5 ">
            <FormField v-slot="{ componentField }" name="customerId">
              <FormItem>
                <FormLabel>{{ $t('customer') }} *</FormLabel>
                <FormControl>
                  <CustomersCombobox v-bind="componentField" :contact="isEditMode ? invoice.customer : undefined" />
                </FormControl>
              </FormItem>
            </FormField>

            <FormField v-slot="{ componentField }" name="reference">
              <FormItem>
                <FormLabel>{{ $t('reference') }}</FormLabel>
                <FormControl>
                  <Input type="text" placeholder="" v-bind="componentField" />
                </FormControl>
              </FormItem>
            </FormField>

            <div class="grid grid-cols-2 col-start-4 row-span-2 gap-4">
              <FormField v-slot="{ componentField }" name="number">
                <FormItem class="flex-auto col-span-2">
                  <FormLabel>{{ $t('invoice_no') }}</FormLabel>
                  <FormControl>
                    <Input type="text" placeholder="INV-9874" v-bind="componentField" />
                  </FormControl>
                </FormItem>
              </FormField>

              <FormField v-slot="{ componentField }" name="issueDate">
                <FormItem class="flex-auto">
                  <FormLabel>{{ $t('issue_date') }} *</FormLabel>
                  <FormControl>
                    <DatePicker v-bind="componentField" default-current-date />
                  </FormControl>
                </FormItem>
              </FormField>

              <FormField v-slot="{ componentField }" name="dueDate">
                <FormItem>
                  <FormLabel>{{ $t('due_date') }} *</FormLabel>
                  <FormControl>
                    <DatePicker v-bind="componentField" />
                  </FormControl>
                </FormItem>
              </FormField>
            </div>

            <FormField v-slot="{ componentField }" name="billingAddress">
              <FormItem class="col-start-5 row-span-2">
                <FormLabel>{{ startCase($t('billing_address')) }}</FormLabel>
                <FormControl>
                  <AddressFields v-bind="componentField" />
                </FormControl>
              </FormItem>
            </FormField>

            <div class="flex gap-4">
              <FormField v-slot="{ componentField }" name="taxType">
                <FormItem class="flex-auto">
                  <FormLabel>{{ $t('tax_type') }} *</FormLabel>
                  <Select v-bind="componentField">
                    <SelectTrigger class="w-full">
                      <SelectValue placeholder="Select a type" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectGroup>
                        <SelectItem v-for="(type, key) in tax_types" :value="key" :key="type">
                          {{ $t(type) }}
                        </SelectItem>
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                </FormItem>
              </FormField>
              <FormField v-slot="{ componentField }" name="status">
                <FormItem class="flex-auto">
                  <FormLabel>{{ $t('status') }} *</FormLabel>
                  <Select v-bind="componentField">
                    <SelectTrigger class="w-full">
                      <SelectValue placeholder="Select a status" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectGroup>
                        <SelectItem v-for="(status, key) in statuses" :value="key" :key="key">
                          {{ $t(status) }}
                        </SelectItem>
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                </FormItem>
              </FormField>
            </div>

            <FormField v-slot="{ componentField }" name="currencyCode">
              <FormItem>
                <FormLabel>{{ $t('currency_code') }} *</FormLabel>
                <FormControl>
                  <ComboBox v-bind="componentField" :listItems="comboBoxItems" />
                </FormControl>
              </FormItem>
            </FormField>
          </div>

          <FieldArray v-slot="{ fields }" name="items">
            <ProductItems :fields="fields" />
          </FieldArray>

          <DefineTaxesTemplate>
            <template v-for="({ taxName, totalTaxValue }, keyTaxPercentage) in summarize.taxes">
              <div class="flex justify-between gap-2 mb-3">
                <div class="">{{ taxName }} ({{ Number(keyTaxPercentage).toFixed(2) + '%' }})</div>

                <div class="flex justify-between w-1/3">
                  <div class="px-3">{{ invoice.currencyCode }}</div>
                  <div class="text-right">{{ Number(totalTaxValue).toFixed(2) }}</div>
                </div>
              </div>
            </template>
          </DefineTaxesTemplate>

          <div class="grid justify-end grid-cols-4 pr-2">
            <div class="col-span-1 col">
              <FormField v-slot="{ componentField }" name="notes">
                <FormItem>
                  <FormLabel>{{ $t('notes') }}</FormLabel>
                  <FormControl>
                    <Textarea type="text" placeholder="Notes" v-bind="componentField" />
                  </FormControl>
                </FormItem>
              </FormField>
            </div>
            <div class="flex justify-end col-span-2 col-start-3">
              <div class="w-full max-w-lg border-b">
                <div class="flex justify-between gap-2 mb-3">
                  <div class="py-2">
                    {{ $t('subtotal') }}
                  </div>
                  <div class="flex justify-between w-1/3">
                    <div class="px-3 py-2">{{ invoice.currencyCode }}</div>
                    <div class="py-2 text-right">{{ Number(summarize.subtotal).toFixed(2) }}</div>
                  </div>
                </div>
                <div class="max-w-xs pl-4 text-sm">
                  <ReuseTaxesTemplate v-if="storeFormInvoice.invoiceFormData?.taxType === 'tax_inclusive'" />
                </div>
                <ReuseTaxesTemplate v-if="storeFormInvoice.invoiceFormData?.taxType === 'tax_exclusive'" />
                <div class="flex justify-between py-3 text-lg font-semibold border-t-4 border-double">
                  <div>{{ upperCase($t('total')) }}</div>
                  <div>{{ Number(total).toFixed(2) }}</div>
                </div>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    </SingleWrap>
  </form>
</template>
