<script setup lang="ts">
import { Button } from '~/Components/ui/button'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '~/Components/ui/dialog'

import { t } from '~/entrypoints/i18n'
import OutstandingInvoices from '~/pages/Organizations/CreditNotes/components/OutstandingInvoices/OutstandingInvoices.vue'
import { TCustomer } from '~/types/Generic'
import { startCase } from 'lodash'
import { organizationsCreditNotes } from '~/api'

const props = withDefaults(defineProps<{
  creditNoteId: number,
  buttonLabel?: string,
  contact: TCustomer | undefined,
}>(), {
  buttonLabel: startCase(t('allocate_credit'))
})

const applyAmount = ref<number>(0)

const moduleApi = useModuleApiStore()

function onSubmit() {
  const params = {
    id: props.creditNoteId
  }

  const data = {
    amount: applyAmount.value,
    invoice_id: 1
  }

  moduleApi.gotoUrl(organizationsCreditNotes.allocate, { method: 'patch', params: params, data: data })

  // moduleApi.fetchUrl(organizationsCreditNotes.allocate, {
  //   params: {
  //     id: props.creditNoteId
  //   },
  //   data: data
  // })
}
</script>

<template>
  <Dialog>
    <DialogTrigger as-child>
      <Button variant="outline">
        {{ buttonLabel }}
      </Button>
    </DialogTrigger>
    <DialogContent class="max-w-4xl">
      <DialogHeader>
        <DialogTitle>{{ startCase(t('allocate_credit_balance')) }}</DialogTitle>
        <DialogDescription>
          <i18n-t keypath="allocate_credit_description">
            <a href="#" class="text-sky-600">Credit Note CN-007</a>
          </i18n-t>
        </DialogDescription>
      </DialogHeader>

      <OutstandingInvoices :contact="contact" v-model="applyAmount" />

      <DialogFooter class="pt-4">
        <Button @click="onSubmit" form="dialogForm">
          {{ startCase(t('allocate_credit')) }}
        </Button>
        <DialogClose as-child>
          <Button type="button" variant="outline">
            {{ startCase(t('cancel')) }}
          </Button>
        </DialogClose>
      </DialogFooter>
    </DialogContent>
  </Dialog>
</template>
