import { HTMLAttributes } from "vue";
import { BadgeVariants } from "../ui/badge";

function variantFromStatus(status: string) {
  switch (status) {
    case "draft":
      return "outline";
    case "pending":
      return "destructive";
    case "approved":
      return "outline";
    case "paid":
      return "online";
    default:
      return "secondary";
  }
}

function classFromStatus(status: string) {
  switch (status) {
    case "draft":
      return "text-foreground dark:text-background bg-slate-300 dark:bg-slate-400";
    case "pending":
      return "bg-destructive text-destructive-foreground";
    case "approved":
      return "text-primary-foreground bg-sky-500";
    case "paid":
      return "bg-emerald-500 text-primary-foreground";
    default:
      return "bg-secondary text-secondary-foreground";
  }
}

export function btnPropsFromStatus(status: string): {
  variant: BadgeVariants["variant"];
  class: HTMLAttributes["class"];
} {
  return {
    variant: variantFromStatus(status),
    class: classFromStatus(status),
  };
}
