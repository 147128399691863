<script setup lang="ts">
import { usePage } from '@inertiajs/vue3';
import { createReusableTemplate, useVModel } from '@vueuse/core';
import { find, isNil } from 'lodash';
import { inject, Ref, ref, watch } from 'vue';

import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from '~/Components/ui/command';
import { Popover, PopoverContent, PopoverTrigger } from '~/Components/ui/popover';
import { t } from '~/entrypoints/i18n';
import Input from './Input.vue';
import { useModuleApiStore } from '~/stores/moduleApi';
import { OrganizationsTaxesIndex } from '~/types/serializers';

defineOptions({ inheritAttrs: false })

const moduleApi = useModuleApiStore()
moduleApi.setVersion(usePage().version)

const [DefineTaxListTemplate, ReuseTaxListTemplate] = createReusableTemplate()
const props = defineProps<{
  defaultValue?: string | number
  modelValue?: string | number
  taxItem?: OrganizationsTaxesIndex
}>()
const emits = defineEmits<{
  (e: 'update:modelValue', payload: string | number): void
  (e: 'update:taxItem', payload: OrganizationsTaxesIndex | null): void
}>()

const taxId = useVModel(props, 'modelValue', emits, { passive: true })
const taxItem = useVModel(props, 'taxItem', emits, { passive: true })

const isOpen = ref(false)
const selectedTaxName = ref('')

const { taxList } = inject<{
  taxList: Ref<OrganizationsTaxesIndex[]> | null
}>('taxList', { taxList: null } )

watch([() => taxList?.value, () => taxId.value], ([watchedTaxList, watchedTaxId], [, prevTaxId]) => {
  if (!watchedTaxList) return // !watchedTaxId: skip unnecessary event trigger

  if (!watchedTaxId && isNil(watchedTaxId) === isNil(prevTaxId)) {
    clearTaxSelection()
    return
  }

  const matchedTax = find(watchedTaxList, (o) => o.id === watchedTaxId)
  if (matchedTax) {
    if (taxItem.value !== matchedTax) emits('update:taxItem', matchedTax) // skip unnecessary event trigger on uncontrolled value

    selectedTaxName.value = matchedTax.name
  }
  else {
    clearTaxSelection
  }
}, { immediate: true })

function onItemSelect(pickedItem: OrganizationsTaxesIndex) {
  selectedTaxName.value = pickedItem.name
  taxId.value = pickedItem.id
  taxItem.value = pickedItem
  isOpen.value = false
}

function clearTaxSelection() {
  selectedTaxName.value = ""
  taxId.value = undefined
  emits('update:taxItem', null)
  isOpen.value = false
}
</script>

<template>
  <Popover v-model:open="isOpen">
    <PopoverTrigger as-child>
      <Input type="text" placeholder="Select tax" variant="naked" v-model="selectedTaxName" />
    </PopoverTrigger>
    <PopoverContent class="w-[200px] p-0" align="start">
      <ReuseTaxListTemplate />
    </PopoverContent>
  </Popover>

  <DefineTaxListTemplate>
    <Command>
      <CommandInput placeholder="Filter status..." />
      <CommandList>
        <CommandEmpty>{{ t('no_result_found') }}</CommandEmpty>
        <CommandGroup>
          <CommandItem value="no_tax" @select="clearTaxSelection" v-if="taxList" class="cursor-pointer">
            {{ t('no_tax') }}
          </CommandItem>
          <CommandItem v-for="tax of taxList" :key="tax.id" :value="tax.id || '0'" @select="onItemSelect(tax)"
            v-if="taxList" class="cursor-pointer">
            <div class="w-full">
              {{ tax.name }}
            </div>
          </CommandItem>
        </CommandGroup>
      </CommandList>
    </Command>
  </DefineTaxListTemplate>
</template>
