import { z } from "zod";

import { orderBasedSchema as billZodSchema } from "~/types/OrderBased";

export type { TProductItem, TProductItemRow } from "~/types/OrderBased";

import type { default as TBillIndex } from "~/types/serializers/Organizations/Bills/Index";
export type TBillBase = TBillIndex

// Type bill structure differ from sale_order at "contact" & "customer"
export const billSchema = billZodSchema
  .omit({
    customer: true,
    customerId: true,
  })
  .merge(
    z.object({
      contactId: z.number(),
      contact: z
        .object({
          id: z.number(),
          name: z.string(),
        })
        .optional()
        .nullable(),
    })
  );

export type TBill = z.infer<typeof billSchema>;
