// JsFromRoutes CacheKey b0b1af7daddbb8a669cd94e405b6e0ce
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  void: /* #__PURE__ */ definePathHelper('patch', '/organization/bills/:id/void'),
  index: /* #__PURE__ */ definePathHelper('get', '/organization/bills'),
  create: /* #__PURE__ */ definePathHelper('post', '/organization/bills'),
  new: /* #__PURE__ */ definePathHelper('get', '/organization/bills/new'),
  edit: /* #__PURE__ */ definePathHelper('get', '/organization/bills/:id/edit'),
  show: /* #__PURE__ */ definePathHelper('get', '/organization/bills/:id'),
  update: /* #__PURE__ */ definePathHelper('patch', '/organization/bills/:id'),
  destroy: /* #__PURE__ */ definePathHelper('delete', '/organization/bills/:id'),
}
